@tailwind base;
@tailwind components;
@tailwind utilities;

.notification-slide-in {
  animation: slide-in 0.5s ease forwards;
}

@keyframes slide-in {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}

.notification-slide-in-opacity {
  animation: slide-in-opacity 0.5s ease forwards;
}

@keyframes slide-in-opacity {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

.notification-slide-left {
  animation: slide-left 0.25s linear forwards;
}

@keyframes slide-left {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-120%);
  }
}

.full-screen-container {
  @apply w-full min-h-screen flex justify-center items-center;
}
